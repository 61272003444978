import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Header.css';
import pizzalogo from "../../assets/Images/Home/PizzaLogo.png";
import { FaBars, FaTimes } from 'react-icons/fa';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState('Home');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  const MENU_ITEMS = ['Home', 'About', 'Menu', 'Service', 'Location'];
  const menuRoutes = {
    Home: '/',
    About: '/about',
    Menu: '/dessert',
    Service: '/service',
    Location: '/location',
  };

  useEffect(() => {
    const path = location.pathname.substring(1) || 'home';
    const menuMapping = {
      dessert: 'Menu',
      pizzadavalter: 'Menu',
      drinks: 'Menu',
    };
    setSelectedMenu(menuMapping[path.toLowerCase()] || path.charAt(0).toUpperCase() + path.slice(1));
    setSelectedSubMenu(path.charAt(0).toUpperCase() + path.slice(1));
  }, [location]);

  useEffect(() => {
    const handleScroll = () => {
      setScroll(window.scrollY > 50);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleMenuClick = (menu) => {
    if (isMobile) {
      setIsDrawerOpen(false);
    }
    if (menu === 'Dessert' || menu === 'food' || menu === 'Drinks') {
      setSelectedMenu('Menu');
      setSelectedSubMenu(menu);
    } else {
      setSelectedMenu(menu);
      setSelectedSubMenu('');
    }
  };

  const toggleDrawer = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div>
      {!isMobile ? (
        <header className={`header ${scroll ? 'header--scrolled' : ''}`}>
          <div className="header__logo">
            <img src={pizzalogo} alt="Pizza Logo" className="logo" />
            <span className="brand-name">Pizza Da Valter</span>
          </div>
          <nav className="header__menu">
            <ul>
              {MENU_ITEMS.map((menu) => (
                <li
                  key={menu}
                  className={selectedMenu === menu ? 'selected' : ''}
                  onClick={() => handleMenuClick(menu)}
                  onMouseEnter={() => menu === 'Menu' && setIsDropdownOpen(true)}
                  onMouseLeave={() => menu === 'Menu' && setIsDropdownOpen(false)}
                >
                  <Link to={menuRoutes[menu]}>{menu}</Link>
                  {menu === 'Menu' && (
                    <>
                      <button onClick={() => setIsDropdownOpen((prev) => !prev)} className="web-dropdown-toggle">
                        {isDropdownOpen ? '▲' : '▼'}
                      </button>
                      {isDropdownOpen && (
                        <ul className="manu-dropdown-web">
                           <Link className="web-sub-manu" to="/drinks"><li
                            onClick={() => handleMenuClick('Drinks')}
                            className={selectedSubMenu === 'Drinks' ? 'selected-submenu' : ''}
                          >
                            Drinks
                          </li></Link>
                          <Link className="web-sub-manu" to="/pizzadavalter">
                          <li
                            onClick={() => handleMenuClick('food')}
                            className={selectedSubMenu === 'food' ? 'selected-submenu' : ''}
                          >
                           Food
                          </li>
                          </Link>
                         <Link className="web-sub-manu" to="/dessert"> 
                         <li
                            onClick={() => handleMenuClick('Dessert')}
                            className={selectedSubMenu === 'Dessert' ? 'selected-submenu' : ''}
                          >
                            Desserts
                          </li>
                          </Link>
                          
                         
                        </ul>
                      )}
                    </>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="header__order">
            <Link to="/download">
              <button className="order-btn">Join Now</button>
            </Link>
          </div>
        </header>
      ) : (
        <div className="mobile-header">
          <div className="mobile-header-left">
            <img src={pizzalogo} alt="Logo" className="mobile-header-logo" />
            <span className="mobile-header-brand-name">Pizza Da Valter</span>
          </div>
          <div className="mobile-header-right">
            <Link to="/download">
              <button className="mobile-header-extra-button" onClick={() => setIsDrawerOpen(false)}>
                Join Now
              </button>
            </Link>
            <button
              className="mobile-header-drawer-toggle"
              onClick={toggleDrawer}
              aria-expanded={isDrawerOpen}
              aria-label={isDrawerOpen ? "Close menu" : "Open menu"}
            >
              {isDrawerOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
            </button>
          </div>
          {isDrawerOpen && (
            <div className="mobile-header-drawer mobile-header-drawer-open">
              <ul>
                {MENU_ITEMS.map((menu) => (
                  <li key={menu} className={selectedMenu === menu ? 'selected' : ''}>
                    <Link to={menuRoutes[menu]} onClick={() => handleMenuClick(menu)}>
                      {menu}
                    </Link>
                    {menu === 'Menu' && (
                      <>
                        <button onClick={() => setIsDropdownOpen((prev) => !prev)} className="dropdown-toggle">
                          {isDropdownOpen ? '▲' : '▼'}
                        </button>
                        {isDropdownOpen && (
                          <ul className="mobile-dropdown">
                             <Link className="mobile-header-submanu" to="/drinks">
                            <li
                              onClick={() => handleMenuClick('Drinks')}
                              className={selectedSubMenu === 'Drinks' ? 'selected-submenu' : ''}
                            >
                              Drinks
                            </li>
                            </Link>
                            <Link className="mobile-header-submanu" to="/pizzadavalter">
                            <li
                              onClick={() => handleMenuClick('food')}
                              className={selectedSubMenu === 'food' ? 'selected-submenu' : ''}
                            >
                              Food
                            </li></Link>
                            <Link className="mobile-header-submanu" to="/dessert">
                            <li
                              onClick={() => handleMenuClick('Dessert')}
                              className={selectedSubMenu === 'Dessert' ? 'selected-submenu' : ''}
                            >
                              Desserts
                            </li>
                            </Link>
                            
                           
                          </ul>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
